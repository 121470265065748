import "./sadiqContactCard.css";

const ContactCard = () => {
  return (
    <div className="contact-card">
      <div className="contact-card-mask">
        <div className="contactCard-container">
          <p className="contact-title">Email Us Today:</p>
          <p className="contact-email">info@collegiumbuilt.com</p>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
